import axios from "axios";

export async function userLoginCheck (dispatch) {
    const logined = window.localStorage.getItem("logined");
    if( logined ){
        dispatch({type:"CHECKING_LOGIN"});
        const res = await axios.post('/api/logincheck/')
        if( res.data.error == 'ok'){
            const payload = {username:res.data.result}
            dispatch({type:"LOGIN_SUCCESS", payload:payload});
            window.localStorage.setItem("logined", true);
            return true;
        }
        dispatch({type:"LOGOUT"});
        return false;
    }else{
        dispatch({type:"LOGOUT"});
        return false;
    }
}

export async function userLogin ( dispatch, loginPayload){
    const {email, password} = loginPayload;
    const res = await axios.post('/api/login/',  {email : email, password : password});
    if( res.data.error == 'ok'){
        const payload = {username:res.data.result}
        dispatch({type:"LOGIN_SUCCESS", payload:payload});
        window.localStorage.setItem("logined", true);
        return true;
    }else if( res.data.error == 'error'){
        dispatch({type:"LOGIN_ERROR", error: res.data.result});
        throw Error(res.data.result);
    }else{
        dispatch({type:"LOGIN_ERROR", error: "SYSTEM"});
        throw Error("system");
    }
}

export async function userLogout ( dispatch ){
    dispatch({type:"LOGOUT"});
    window.localStorage.removeItem("logined",false);
    const res = await axios.post('/api/logout/');
    return;
}
