import { useState, useEffect } from "react";
import axios from "axios";
import {Zoom, Slide} from 'react-slideshow-image'

import './ItemThumbnailShow.css'


export const ItemThumbnailShow = (props) =>{
    const [imageList, setImageList] = useState("loading");


    const imageListLoading = async ( item_idx ) => {
        try{
            const res = await axios.post("/api/item/images/",{item_idx:item_idx})
            if( res.data.error == 'ok'){
                setImageList( res.data.result )
                console.log( res.data.result)
            }
        }catch(error){
            console.log(error)
            setImageList("error")
        }
    }

    useEffect(()=>{
        imageListLoading(props.item_idx)
    },[])

    if( imageList == 'loading'){
        return (
            <div className="itemThumbnailShowMessage">
                ...
            </div>
        )
    }else if( imageList == 'error'){
        return (
            <div className="itemThumbnailShowMessage">
                error
            </div>
        )
    }else{
        const zoomInProperties = {
            indicators: false,
            scale: 1.4
          }
        return (
            <div className="itemThumbnailShowWrap">
                {imageList.map( (filename, index) => {
                    const img_url = "https://luxury.platformbees.com/img/?filename=" + filename
                    if( index == 0  ){
                        return (
                            <div className="itemThumbnail">
                                <img src={img_url} />
                            </div>
                        )
                    }
                })}
            </div>
            
        )
    }
}