import { useEffect, useState } from "react";
import { TopMenu } from "./TopMenu";
import './ItemDetailPage.css'
import axios from "axios";
/*import {Swiper, SwiperSlider} from 'swiper/react'
import "swiper/css";
*/
const ItemDataInitial = {
    loadingStatus : 'loading',
    item_code : '',
    color_code : '',
    supplier : '',
    collection : '',
    order_deadline : '',
    brand_name : '',
    description : '',
    price_original : '',
    price_sales : '',
    sizes : [],
    updated : '',
    images: [],
    errorMessage : '',
}

export const ItemDetailPage = (props) =>{
    const [screenMode, setScreenMode] = useState("unknown");
    const [itemData, setItemData] = useState(ItemDataInitial);

    const {params} = props.match;
    const item_code = params.item_code;

    useEffect( ()=>{
        //async item data loading...
        axios.post("/api/item/",{item_code:item_code})
        .catch(error => {
            return {data:{error:"error",result:"system"}}
        })
        .then( res => {
            console.log(res);
            if( res.data.error == 'ok'){
                const newItemData = {
                    ...ItemDataInitial,
                    loadingStatus:'done',
                    ...res.data.result,
                }
                console.log(newItemData)
                setItemData(newItemData)
            }else{
                const newItemData = {
                    ...ItemDataInitial,
                    loadingStatus : 'error'
                }
                setItemData(newItemData)
            }
        })

        function updateScreenSize() {
            if( window.innerWidth > 780 ){
              setScreenMode("desktop");
            }else{
              setScreenMode("mobile");
            }
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    },[])

    const on_back_to_list_clicked = e => {
        props.history.goBack();
    }
    
    if( itemData.loadingStatus == 'loading'){
        return (
            <div className="itemDetail">
                <TopMenu/>
                <div className="BackToWrap">
                    <span onClick={on_back_to_list_clicked} className="BackToButton">Back to list</span>
                </div>
                <div className="loadingWrap">
                    Item Data Loading...
                </div>
            </div>
        )
    }else if( itemData.loadingStatus == 'error'){
        return (
            <div className="itemDetail">
                <TopMenu/>
                <div className="BackToWrap">
                    <span onClick={on_back_to_list_clicked} className="BackToButton">Back to list</span>
                </div>
                <div className="error">
                    Item Data Loading error occurred.
                </div>
            </div>
        )
    }else if( itemData.loadingStatus == 'done' ){
        if( screenMode == "mobile"){
            return (
                <div className="itemDetail">
                    <TopMenu/>
                    <div className="BackToWrap">
                        <span  onClick={on_back_to_list_clicked} className="BackToButton">Back to list</span>
                    </div>
                    <div className="itemInfo mobile">
                        <div className="imageSwipwerWrap">
                            Image Swiper Area
                        </div>
                        <ItemDetailInfo data={itemData} />
                    </div>
                </div>
            )
        }else if( screenMode == "desktop" ){
            return (
                <div className="itemDetail">
                    <TopMenu/>
                    <div className="BackToWrap">
                        <span onClick={on_back_to_list_clicked} className="BackToButton">Back to list</span>
                    </div>
                    <div className="itemInfo desktop">
                        <div className="imageTileWrap">
                            <ImageTile data={itemData.images} />
                        </div>
                        <ItemDetailInfo data={itemData} />
                    </div>
                </div>
            )
        }
    }
}

const ItemDetailInfo = (props) => {
    const data = props.data;
    return (
        <div className="ItemDetailInfo">
            <div className="brand">{data.brand_name}</div>
            <div className="description">{data.description}</div>
            <div className="price_wrap">
                <span className="price_original">{data.price_original}</span>
                <span className="price_sales">{data.price_sales}</span>
            </div>
            <div className="sizes">
                { data.sizes.map( (size_n_stock) =>  
                <div className="size_n_stock">
                    <span className="size">{size_n_stock.size}</span>
                    <span className="stock">{size_n_stock.stock}</span>
                </div>
                )}
            </div>
            <div className="add_to_cart_button">
                Add to Cart
            </div>
            <div className="details">
                <div className="title">Details</div>
                <table>
                    <tbody>
                        <tr><td>Brand</td><td>{data.brand_name}</td></tr>
                        <tr><td>Description</td><td>{data.description}</td></tr>
                        <tr><td>Item Code</td><td>{data.item_code}</td></tr>
                        <tr><td>Color Code</td><td>{data.color_code}</td></tr>
                        <tr><td>Supplier</td><td>{data.supplier}</td></tr>
                        <tr><td>Collection</td><td>{data.collection}</td></tr>
                        <tr><td>Retail Price in Italy</td><td>{data.price_original}</td></tr>
                        <tr><td>Order Deadline</td><td>{data.order_deadline}</td></tr>
                        <tr><td>List Updated Date</td><td>{data.updated}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const ImageTile = (props) => {
    const images = props.data;
    let image_count = 0;
    try{
        image_count = images.length;
    }catch(error){
        image_count = 0;
    }
    if( image_count == 0 ){
        return(
            <>
                no image
            </>
        )
    }else if( image_count == 1 ){
        return(
            <div className="image_tile_only_one">
                <img src={images[0]} />
            </div>
        )
    }else{
        let image_list = []
        for(let i= 0 ; i < image_count ; i++){
            let left = images[i++];
            let right = (i<image_count)?images[i]:false;
            image_list.push({left:left,right:right});
        }
        console.log(image_list)

        return(
            <div className="image_tile">
                {image_list.map( (row)=>
                    <div className="image_tile_row">
                        <div className="image_tile_left">
                            <img src={row.left} />
                        </div>
                        {row.right&&
                            <div className="image_tile_right">
                                <img src={row.right} />
                            </div>
                        }
                    </div>
                 )}
            </div>
        )
    }
}

/*
                
                
*/