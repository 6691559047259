import React,{useRef, useState} from 'react';
import {Link} from 'react-router-dom'
import './Login.Page.css'
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useUserDispatch, useUserState, userLogin} from '../context'

export const LoginPage = (props) => {
    const {loadingForLogin} = useUserState();
    const userDispatch = useUserDispatch();

    //const [isLogging, setIsLogging] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMsg, setAlertMsg] = useState("")
    const email = useRef("")
    const password = useRef("")

    const handleClose = () => {
        setOpenAlert(false);
    }

    const on_submit =e=> {
        e.preventDefault();
        const loginPayload = {email: email.current.value, password:password.current.value}
        userLogin(userDispatch, loginPayload)
        .then( res =>{
            console.log(res);
            if( res ){
                props.history.push("/")
            }else{
                //여기 들어올 수 없음 => 오류
            }
        })
        .catch(error => {
            if( error.message == "system"){
                setAlertMsg("시스템오류입니다.")
            }else if( error.message == "no user"){
                setAlertMsg("이메일이나 비밀번호를 확인하세요.")
            }else{
                setAlertMsg("알 수 없는 오류가 발생했습니다.")
            }
            setOpenAlert(true)
        });
    }
    let btnMsg = (loadingForLogin)?"Login in progress...":"Login"
    let msg = "상품을 보시려면 로그인이 필요합니다."
    return (
        <div className="loginWrap">
            <h1 className="title">Italy Luxury Goods Platform</h1>
            <h2 className="subtitle">로그인</h2>
            <h3 className="msg">{msg}</h3>
            <form onSubmit={on_submit} >
                <input name="email" type="text" ref={email} placeholder="이메일*"></input>
                <input name="password" type="password" ref={password} placeholder="비밀번호*"></input>
                <span>비밀번호를 잊으셨습니까?</span>
                <button disabled={loadingForLogin}>{btnMsg}</button>
            </form>
            <div className="submsg">회원이 아니십니까? <Link to="/register">회원가입</Link>으로 이동하세요. </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openAlert}
                autoHideDuration={3000}
                onClose={handleClose}
                message={alertMsg}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    )

    
}
