import React, {useState} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { TopMenu } from './TopMenu';
import { ItemList } from './ItemList';
import { HoverToTopBtn } from '../components/HoverToTopBtn';


export const MainPage = (props) => {
    //const location = useLocation(); //history에서 state를 읽을려고 쓴다.. 실제론 쓸 일 없을 것 같음
    //console.log( location);
    
    return (
        <div className="MainPage">
            <TopMenu/>
            <ItemList />
            <HoverToTopBtn />
        </div>
    )
}