
import './ModalCartPage.css'


export const ModalCartPage = (props) => {
    
    const on_close_button_clicked = () => {
        props.setCartPageVisible(!props.visible);
    }

    const CloseButton = () =>{
        return (
            <span onClick={on_close_button_clicked} className="closeButton">
                <img src="/images/close_black_24dp.svg"></img>
            </span>
        )
    }


    const cartPageStyle = {
        display : (props.visible?'block':'none'),
    }
    return (
        <div className="modalCartPage" style={ cartPageStyle }>
            <div className="cartPageWrap">
                
                <div className="toplist">
                    <span className="title">장바구니</span>    
                </div>
                <CloseButton />
            </div>
        </div>
    )
}