import React,{useState, useEffect} from "react"
import './ItemList.css'
import { Link } from "react-router-dom"
import axios from "axios"
import { ItemThumbnailShow } from "../components/ItemThumbnailShow"

export const Item = ({data, isMobile}) => {
    const dataProto = {
        brand : "",
        collection : "",
        color : "",
        color_code : "",
        item_category1 : "",
        item_code : "",
        description : "",
        //order_deadline : "",
        price_retail_it_eur : "",
        price_sales : "",
        stock : 0,
    }
    let itemdata = {...dataProto, ...data }

    console.log("isModbile" + isMobile)

    if( isMobile == true ){
        return (
            <div className="ItemMobile">
                <Link to={"/item/"+itemdata.item_code}>
                    <div className="imageWrap">
                        <ItemThumbnailShow item_idx={itemdata.item_idx}></ItemThumbnailShow>
                    </div>
                </Link>
                <div className="textWrap">
                    <div className="brand">{itemdata.brand} ( {itemdata.item_category1} )</div>
                    <div className="item_code">{itemdata.item_code} / {itemdata.color_code}</div>
                    <div className="spacer"></div>
                    <div className="description">{itemdata.description}</div>
                    <div className="order_deadline">~ {itemdata.order_deadline}</div>
                </div>
                <div className="priceWrap">
                    <div className="priceOriginal">
                        {itemdata.price_retail_it_eur}
                    </div>
                    
                    <div className="priceSales">{itemdata.price_sales}</div>
                    <div className="spacer"></div>
                    <div className="stock">Stock {itemdata.stock}</div>
                </div>
            </div>
        )
    }else if( isMobile == false ){
        return (            
            <tr className="ItemDesktop">
                <td className="image">
                    <Link to={"/item/"+itemdata.item_code} >
                        <div className="imageWrap">
                            <ItemThumbnailShow item_idx={itemdata.item_idx}></ItemThumbnailShow>
                        </div>
                    </Link>
                </td>
                <td className="textWrap">
                    <div className="brand">{itemdata.brand} ( {itemdata.item_category1} )</div>
                    <div className="spacer"></div>
                    <div className="description">{itemdata.description}</div>
                    <div className="order_deadline">until {itemdata.order_deadline}</div>
                </td>
                <td className="item_code">{itemdata.item_code}</td>
                <td className="color_code">{itemdata.color_code}</td>
                <td className="priceOriginal">{itemdata.price_retail_it_eur}</td>
                <td className="priceSalesWrap">
                    <div className="priceSales">{itemdata.price_sales}</div>
                    <div className="spacer"></div>
                    <div className="stock">Stock {itemdata.stock}</div>
                </td>
            </tr>
        )
    }else{ //unknown
        return ( <></> )
    }
}


export const ItemList = () =>{
    const [screenMode, setScreenMode] = useState("unknown");
    const [itemdata, setItemData] = useState("loading");
    

    const itemDataLoading = async (options) => {
        try{
            const res = await axios.post("/api/item/list/",{})
            if( res.data.error == 'ok'){
                setItemData( res.data.result )
                console.log(res.data.result)
            }
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        //loading Item Data
        itemDataLoading()

        //screen size calc
        function updateScreenSize() {
            if( window.innerWidth > 1080 ){
                setScreenMode("tablet");
            }else if( window.innerWidth < 780 ){
                setScreenMode("mobile");
            }else{
                setScreenMode("desktop");
            }
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
return () => window.removeEventListener("resize", updateScreenSize);
    }, []);
    
    const data = {
        brand: "1017 ALYX 9SM",
        collection: "21FW",
        color: "BLACK",
        color_code: "BLACK",
        description: "HAT",
        item_category1: "man",
        item_code: "AAUHA0052FA01",
        item_idx: "2457",
        price_retail_it_eur: "285",
        stock : 3,

    }
    if( itemdata == 'loading'){
        return(
            <div className="itemlistMessage">
                Loading...
            </div>
        )
    }
    if( screenMode == "mobile" ){
        return (
            <div className="ItemListWrap">
                <div className="FilterButtonWrap">
                    <span className="FilterButton">Filter</span>
                </div>
                <div className="ItemList">
                    {itemdata.map( (data,index)=>{
                        return (
                            <Item data={data} isMobile={true} />
                        )
                    })}
                </div>
                
            </div>
        )
    }else if( screenMode == "desktop" ){
        return (
            <div className="ItemListWrap">
                <div className="FilterButtonWrap">
                    <span className="FilterButton">Filter</span>
                </div>
                <table className="ItemListTable">
                    <thead>
                        <tr className="head">
                            <th></th>
                            <th>Brand Name</th>
                            <th>Item Code</th>
                            <th>Color Code</th>
                            <th style={{textAlign:"right"}}>Original Price</th>
                            <th style={{textAlign:"right"}}>Sale Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemdata.map( (data,index)=>{
                            return (
                                <Item data={data} isMobile={false} />
                            )
                        })}
                        
                    </tbody>
                </table>
            </div>
        )
    }else if( screenMode == "tablet" ){
        return (
            <div className="ItemListWrap tablet">
                <div className="FilterAreaWrap">
                    Filter Options are here
                </div>
                <div className="ItemListTableAreaWrap">
                    <table className="ItemListTable">
                        <thead>
                            <tr className="head">
                                <th></th>
                                <th>Brand Name</th>
                                <th>Item Code</th>
                                <th>Color Code</th>
                                <th style={{textAlign:"right"}}>Original Price</th>
                                <th style={{textAlign:"right"}}>Sale Price</th>
                            </tr>
                        </thead>
                        <tbody>
                        {itemdata.map( (data,index)=>{
                            return (
                                <Item data={data} isMobile={false} />
                            )
                        })}
                           
                    
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }else{
        return (
            <>Loading...</>
        )
    }
}
