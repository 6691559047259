import { useEffect } from 'react';
import {userLogout, useUserDispatch} from '../context'

export const LogoutPage = (props) => {
    const userDispatch = useUserDispatch();

    useEffect( ()=> {
        userLogout(userDispatch)
        .then( res => {
            props.history.push("/")
        })
        .catch(error=>{
            console.log(error.message)
            props.history.push("/")
        })
    },[])

    return (
        <>
            Logging out...
        </>
    )

}