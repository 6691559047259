//let token = localStorage.getItem("token_login");
 
export const initialUserState = {
    logined: "unknown",
    username : "",
    loadingForLogin : false,
    loadingForLoginCheck : false,
    errorMessageInLogin : null,
    //token : "" || token,
};
 
export const UserReducer = (state, action) => {
  switch (action.type) {
    case "CHECKING_LOGIN" : 
      return {
        ...state,
        logined : "unknown",
        loadingForLogin:false,
        loadingForLoginCheck:true,
      }
    case "REQUEST_LOGIN":
      return {
        ...state,
        logined : false,
        loadingForLogin: true,
        loadingForLoginCheck:false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        logined:true,
        username: action.payload.username,
        loadingForLogin: false,
        loadingForLoginCheck:false,
      };
    case "LOGOUT":
      return {
        ...state,
        logined:false,
        username:""
      };
 
    case "LOGIN_ERROR":
      return {
        ...state,
        loadingForLogin: false,
        loadingForLoginCheck :false,
        errorMessageInLogin: action.error
      };
 
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};