import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './ModalLeftMenu.css'
import { useUserState } from '../context';

export const ModalLeftMenu = (props) => {
    const {logined} = useUserState();

    const [topMenuMode, setTopMenuMode] = useState("women");
    const on_men_clicked = () => {
        setTopMenuMode("men");
    }

    const on_women_clicked = () => {
        setTopMenuMode("women");
    }

    const ModeMen = () => {
        return  (
            <span onClick={on_men_clicked} className={topMenuMode=="men"?"active":"false"}>남성</span>
        )
    }

    const ModeWomen = () => {
        return (
            <span onClick={on_women_clicked} className={topMenuMode=="women"?"active":"false"}>여성</span>
        )
    }

    const on_close_button_clicked = () => {
        props.setMenuVisible(false);
    }

    const CloseButton = () => {
        return (
            <span onClick={on_close_button_clicked} className="closeButton">
                <img src="/images/close_black_24dp.svg"></img>
            </span>
        )
    }
    
    const LoginLogoutButton = () => {
        if( logined ){
            return (
                <Link to="/logout">로그아웃</Link>
            )
        }else{
            return (
                <Link to="/login">로그인</Link>
            )
        }
    }

    const menuStyle = {
        display :  (props.visible?'block':'none'),
    }
    const bottomlistStyle = {
        display: 'none',
    }

    return(
        <div className="modalLeftMenu" style={ menuStyle }>
            <div className="menuWrap">
                <div className="toplist">
                    <ModeWomen /> <ModeMen /> <CloseButton />
                </div>
                <div className={"menuList" + ((topMenuMode=="women")?" active":"")}>
                    <div className="item">NEW IN</div>
                    <div className="item"></div>
                    <div className="item">SHOES</div>
                    <div className="item">BAGS</div>
                    <div className="item">RTW</div>
                    <div className="item"></div>
                    <div className="item">SPECIAL PRICES</div>
                </div>
                <div className={"menuList" + ((topMenuMode=="men")?" active":"")}>
                    <div className="item">NEW IN2</div>
                    <div className="item"></div>
                    <div className="item">SHOES2</div>
                    <div className="item">BAGS2</div>
                    <div className="item">RTW</div>
                    <div className="item"></div>
                    <div className="item">SPECIAL PRICES</div>
                </div>
                <div className="bottomlist">
                    <LoginLogoutButton style={bottomlistStyle}/>
                </div>
            </div>
        </div>
    )
}