import VerticalAlignTopSharpIcon from '@material-ui/icons/VerticalAlignTopSharp';
import { useState } from 'react';
import './HoverToTopBtn.css'

export const HoverToTopBtn = (props) =>{
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if( scrolled > 300 ){
            setVisible(true)
        }else{
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

    window.addEventListener('scroll', toggleVisible)
    return(
        <div className="hoverToTopBtn" onClick={scrollToTop} style={{display:visible?'inline':'none'}}>
            <VerticalAlignTopSharpIcon fontSize="large">
            </VerticalAlignTopSharpIcon>
        </div>
    )
}