import React, {useRef} from 'react';
import './Register.Page.css'
import {Link} from 'react-router-dom'

export const RegisterPage = (props) => {
    const on_submit =e=>{
        e.preventDefault();
        console.log( "email is " + email.current.value);
    }
    const email = useRef("")

    return (
        <div className="RegisterWrap">
            <h1 className="title">Italy Luxury Goods Platform</h1>
            <h2 className="subtitle">회원가입</h2>
            <h3 className="msg">회원가입을 하시면 가장 빨리 신상품을 구매할 수 있습니다.</h3>
            <form onSubmit={ on_submit } >
                <input name="email" type="text" placeholder="이메일*" ref={email}></input>
                <input name="password" type="password" placeholder="비밀번호*"></input>
                <span>비밀번호는 8자리 이상입니다.</span>
                <button>Register</button>
            </form>
            <div className="submsg">이미 회원 이십니까? <Link to="/login">로그인</Link>으로 이동하세요. </div>
        </div>
    )
}