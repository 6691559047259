import { useState } from 'react';
import { ModalCartPage } from './Modal.CartPage';
import { ModalLeftMenu } from './Modal.LeftMenu';
import {  Link } from 'react-router-dom';
import './TopMenu.css';

export const TopMenu = (props) => {

    const [menuVisible, setMenuVisible] = useState(false);
    const [cartPageVisible, setCartPageVisible] = useState(false);

    function on_menu_button_clicked(){
        setMenuVisible(!menuVisible);
    }

    function on_cart_button_clicked(){
        setCartPageVisible(!cartPageVisible);
    }

    const MenuButton = () => {
        return (
            <span onClick={on_menu_button_clicked} className={"topMenuButton " + (menuVisible?"isMenuVisible":"")}>
               <img src="/images/menu_black_24dp.svg"></img>
            </span>
        )
    }

    return (
        <div className="topMenu">
            <MenuButton className="topMenuButton" />
            <div className="topMenulogo">
                <Link to="/">Luxury Goods</Link>
            </div>
            <div className="topTextMenu">
                <span className="menuItem">Search</span>
                <span className="menuItem">Bookmark</span>
                <Link to="/mypage/" ><span className="menuItem">MyPage</span></Link>
                <span onClick={on_cart_button_clicked} className="menuItem">Cart</span>
            </div>
            <div className="topIconMenu">
                <span className="menuItem"><img src="/images/search_black_24dp.svg"></img></span>
                <span className="menuItem"><img src="/images/favorite_black_24dp.svg"></img></span>
                <Link to="/mypage/" ><span className="menuItem"><img src="/images/account_circle_black_24dp.svg"></img></span></Link>
                <span onClick={on_cart_button_clicked} className="menuItem"><img src="/images/shopping_bag_black_24dp.svg"></img></span>
            </div>
            <ModalLeftMenu visible={menuVisible} setMenuVisible={setMenuVisible} />
            <ModalCartPage visible={cartPageVisible} setCartPageVisible={setCartPageVisible} />
        </div>
    )
}
