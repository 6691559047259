import React, { Component } from 'react'
import {Redirect, Route} from 'react-router-dom'
import { useUserState } from './context';

export const ProtectedRoute = ({ component : Component, ...rest}) => {
    const {logined} = useUserState()
    return (
        <Route 
        {...rest}
        render={props => {
            if( logined == true ){
                return <Component { ...props} />;
            }else{
                return <Redirect to={
                    {
                        pathname: "/login",
                        state:{
                            from : props.location
                        } 
                    }
                }
                />
            }
        }}
        />
    );
};