import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { LoginPage } from './pages/Login.Page';
import { MainPage } from './pages/Main.Page';
import { LogoutPage} from './pages/Logout.Page';
import {MyPage} from './pages/My.Page';
import {ProtectedRoute} from './ProtedtedRoute'
import { RegisterPage } from './pages/Regiser.Page';
import { ItemDetailPage } from './pages/ItemDetail.Page';

import { useEffect, useState } from 'react';


import {useUserDispatch, useUserState, userLoginCheck} from './context'

function App() {
  const { logined } = useUserState();
  const userDispatch = useUserDispatch();

  useEffect( () => {
    if( logined == 'unknown' ){
      userLoginCheck(userDispatch);
    }
  }, []);


  if( logined == 'unknown'){
    return ( 
      <div className="AppLoading">
        Loading...
      </div>
    )
  }else{
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginPage} ></Route>
          <Route exact path="/logout" component={LogoutPage} ></Route>
          <Route exact path="/register" component={RegisterPage} ></Route>
          <ProtectedRoute exact path="/mypage" component={MyPage} />
          <ProtectedRoute exact path="/item/:itemcode" component={ItemDetailPage} />
          <ProtectedRoute path="/" component={MainPage}/>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
