import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import './MyPage.css'
import { TopMenu } from './TopMenu';
import { userLogout, useUserDispatch, useUserState } from '../context';


export const MyPage = (props) => {
    const {username} = useUserState();
    const userDispatch = useUserDispatch();

    const on_logout_button_cliecked = () => {
        userLogout(userDispatch)
    }
/*
    const TopMenu = () => {
        return (
            <div className="topMenu">
                <Link to="/">
                    <div className="BackButton">
                        <img src="/images/arrow_back_ios_black_24dp.svg" />
                    </div>
                </Link>
                <div className="title">
                    MyPage
                </div>
            </div>
        )
    }
*/
    return (
        <div className="MyPage">
            <TopMenu />
            <div className="welcomeBar">
                <div className="welcome">안녕하세요 {username}</div>
                <div className="logoutButton" onClick={on_logout_button_cliecked}>로그아웃</div>
            </div>
        </div>
    )
}